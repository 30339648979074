import { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";

const MAX_WIDTH = 800;
const LIST_ITEM_GAP = 3;

const S = {
  Root: styled.div`
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    gap: 5vh;
  `,

  Section: styled.section`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: ${MAX_WIDTH}px;
    width: 100%;
  `,

  SectionTitle: styled.div`
    max-width: 650px;
    line-height: 1.3;
    margin-bottom: 5vh;
    padding: 0 1em;
  `,

  SectionDescription: styled.div`
    max-width: 650px;
    line-height: 1.3;
    opacity: 0.5;
  `,

  WorkItemsList: styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: ${LIST_ITEM_GAP}vh;
  `,

  WorkItem: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
  `,

  WorkItemImage: styled.img<{ vertical?: boolean }>`
    width: ${MAX_WIDTH}px;
    max-width: 100%;
    display: block;
    min-height: 20vh;
    background: #f0f0f0;
    min-width: 20vw;

    ${(p) =>
      p.vertical &&
      css`
        max-width: 50%;
      `}
  `,

  Divider: styled.div`
    &::after {
      content: "";
      display: block;
      border-bottom: 2px dotted #000;
      width: 20%;
      opacity: 0.3;
    }
  `,
};

export const ScreenWorks = () => {
  return (
    <S.Root>
      <S.Section>
        <S.SectionTitle>
          AI-Generated Mood Boards & Poster Design
          <S.SectionDescription>
            Produced as promotional materials for a series of music events,
            these images aimed to connect with viewers' immediate experiences of
            the atmosphere, characters, and often surreal objects, evoking
            associations that appeal to intuitive and subconscious perceptions.
            <br />
            Midjourney, Adobe Photoshop
          </S.SectionDescription>
        </S.SectionTitle>
        <S.WorkItemsList>
          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/silk.jpg")}
              alt="Silk"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/meh.jpg")}
              alt="Fur"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/bag_1.jpg")}
              alt="Bag"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/inmybag.jpg")}
              alt="inmybag"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/trio.jpg")}
              alt="Trio"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/zhizh.jpg")}
              alt="Zhizh"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/mish.jpg")}
              alt="Silk"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/girll.jpg")}
              alt="Fur"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/telo.jpg")}
              alt="Poster"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/poster.v4.jpg")}
              alt="Poster"
            />

            <BlinkSwitch
              variations={[
                <S.WorkItemImage
                  src={require("./images/sumka_6x9.jpg")}
                  alt="Sumka"
                />,
                <S.WorkItemImage
                  src={require("./images/girlz.jpg")}
                  alt="Girls"
                />,
              ]}
            />
          </S.WorkItem>

          {/* 
          <S.WorkItem>
            <S.WorkItemImage src={require("./images/logo.jpg")} alt="Logo" />
          </S.WorkItem> */}
        </S.WorkItemsList>
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          Poster Design for the Short Film <i>Unity House</i>
        </S.SectionTitle>
        <S.WorkItemsList>
          <S.WorkItem>
            <S.WorkItemImage src={require("./images/UH_01.jpg")} alt="Spider" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/UH_02.jpg")} alt="Spider" />
          </S.WorkItem>
        </S.WorkItemsList>
      </S.Section>
      <S.Section>
        <S.SectionTitle>Cider Brand Identity Design</S.SectionTitle>
        <S.WorkItemsList>
          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/Burli_pr_LOGO.jpg")}
              alt="Spider"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/Burli_pr_page.jpg")}
              alt="Spider"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/Insta_post1.jpg")}
              alt="Bag"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/Insta_post5.jpg")}
              alt="inmybag"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/Insta_post7.jpg")}
              alt="Trio"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/Insta_post3.jpg")}
              alt="Zhizh"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/Burli_pr_03.jpg")}
              alt="Spider"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage vertical src={require("./images/3-1.jpg")} />
            <S.WorkItemImage vertical src={require("./images/zhigs3.jpg")} />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/card.jpg")} alt="Spider" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/tshirt.jpg")}
              alt="Spider"
            />
          </S.WorkItem>
        </S.WorkItemsList>
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          AI-Generated Images
          <S.SectionDescription>
            Other explorations in creating fantasy environments and situations
            that intended to be visually convincing while maintaining the
            oneiric and ethereal qualities of AI.
            <br />
            Midjourney
          </S.SectionDescription>
        </S.SectionTitle>

        <S.WorkItemsList>
          <S.WorkItem>
            <S.WorkItemImage src={require("./images/bbs.jpg")} alt="Logo" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/poster_no-text.jpg")}
              alt="Divan"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/skirt.jpg")}
              alt="Poster"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              vertical
              src={require("./images/armour.jpg")}
              alt="Bag"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/horse.jpg")}
              alt="inmybag"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/baggg.jpg")}
              alt="Trio"
            />
            <S.WorkItemImage
              vertical
              src={require("./images/angel2.jpg")}
              alt="Zhizh"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/both.jpg")} alt="AI" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/zmei.jpg")} alt="AI" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/01 2.jpg")} alt="AI" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/shuba.jpg")} alt="AI" />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/02.jpg")} alt="AI" />
          </S.WorkItem>
        </S.WorkItemsList>
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          Mood Board for a Short Film
          <S.SectionDescription>
            This project explores the use of Photoshop AI, combining photography
            with generated elements to create mood boards, set, and character
            designs for film. Created in collaboration with Daria Zapriagaeva &
            Maria Savykine.
            <br />
            Digital Photography, Adobe Photoshop
          </S.SectionDescription>
        </S.SectionTitle>
        <S.WorkItemsList>
          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/spina 2.jpg")}
              alt="Spina"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/spider.jpg")}
              alt="Spider"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage vertical src={require("./images/rap.jpg")} />
            <S.WorkItemImage vertical src={require("./images/grey_new.jpg")} />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/jungle.jpg")}
              alt="Jungle"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage src={require("./images/bar.jpg")} alt="Bar" />
          </S.WorkItem>
        </S.WorkItemsList>
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          Title Card for the Short Film <i>My Summer in the Wishing Well</i>
        </S.SectionTitle>
        <S.WorkItemsList>
          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/summerv2.jpg")}
              alt="Spider"
            />
          </S.WorkItem>

          <S.WorkItem>
            <S.WorkItemImage
              src={require("./images/summerv3.jpg")}
              alt="Spider"
            />
          </S.WorkItem>
        </S.WorkItemsList>
      </S.Section>

      <S.Section>
        <div>
          Alesya Nesolenova
          <br />
          00alesyan@gmail.com
          <br />
          <img
            src={require("./images/l'etoile.jpg")}
            alt="Alesya Nesolenova"
            style={{ maxWidth: "120px", margin: "10px 0 0 0" }}
          />
        </div>
      </S.Section>
    </S.Root>
  );
};

const BlinkSwitch = ({
  variations,
  interval = 800,
}: {
  variations: ReactNode[];
  interval?: number;
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((index + 1) % variations.length);
    }, interval);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return <>{variations[index]}</>;
};
